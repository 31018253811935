




























import Vue from "vue";
import { mapActions } from "vuex";
import { SEARCH_JOBS } from "@/store/modules/skill_extractor/constants";
export default Vue.extend({
  name: "AutoCompleteJobSearch",
  data: (): Record<string, any> => ({
    search_input: null,
    selected_job: "",
    searched_jobs: [
      {
        text: String(""),
        value: String("")
      }
    ],
    selected_searched_job: null
  }),
  watch: {
    async search_input(value) {
      if (value) {
        const data = await this.search_jobs(value.toLowerCase().trim());
        this.filter_searched_jobs(data);
      }
    },
    selected_searched_job(val) {
      if (val) {
        this.$emit("process_selected_job", val);
        this.reset_local_state();
      }
    }
  },
  methods: {
    ...mapActions("skill_extractor", {
      search_jobs: SEARCH_JOBS
    }),
    // Filter searched result from backend
    filter_searched_jobs(value: any) {
      this.searched_jobs = [];
      if (value && value.data.length > 0) {
        const jobs_data = value.data;
        for (let data of jobs_data) {
          this.searched_jobs.push({
            text: data.job_title,
            value: {
              job_title: data.job_title,
              job_id: data.jid,
              sector: data.sector
            }
          });
        }
      }
    },
    clear_input_field() {
      if (!this.search_input) this.$emit("selected_job", null);
      else {
        const elem = this.$refs.autocomplete_job_search as HTMLElement;
        elem.focus();
      }
      this.reset_local_state();
    },
    // Reset local variable before closing subcomponent
    reset_local_state() {
      this.search_input = null;
      this.selected_job = "";
      this.searched_jobs = [
        {
          text: String(""),
          value: String("")
        }
      ];
      this.selected_searched_job = null;
    }
  }
});
