































import Vue from "vue";
export default Vue.extend({
  name: "PasteCvContent",
  data: (): Record<string, any> => ({
    cv_text: "",
    valid_cv_text: true
  }),
  watch: {
    cv_text(newValue: string) {
      this.valid_cv_text = newValue === "";
    }
  }
});
