




































import Vue from "vue";
export default Vue.extend({
  name: "AnalyzeResumeError",
  data: (): Record<string, any> => ({
    warning_icon: require("@/assets/icons/common/warning.svg")
  }),
  props: {
    error_msg: {
      type: String,
      required: true
    }
  }
});
