
















































































































































































































import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import PasteCvContent from "@/components/skill_extractor/PasteCvContent.vue";
import {
  skills_group,
  extract_skill_group_styling
} from "@/utils/skills_group";
import {
  GET_CV_SKILLS_LOADING,
  GET_TOP_JOB_SECTORS,
  GET_SKILL_EXTRACTOR_ERROR,
  GET_SKILL_EXTRACTOR_ERROR_MSG,
  GET_TOP_JOB_SECTORS_LOADING,
  GETTER_CV_ID,
  GETTER_CV_SKILLS,
  GETTER_TOP_JOB_SECTORS,
  RESET_STATE,
  UPLOAD_CV_AND_GET_SKILLS,
  GET_UPSKILL_COURSES,
  CANCEL_ANALYZING,
  GET_CANCEL_ANALYZING,
  GET_SEARCH_JOB_LOADING,
  GET_SECTOR,
  GET_TOP_JOB_WITH_INDEX,
  SET_UPLOAD_VIEW_RESUME,
  GET_UPLOAD_VIEW_RESUME
} from "@/store/modules/skill_extractor/constants";
import AnalyzeResumeLoader from "@/components/skill_extractor/AnalyzeResumeLoader.vue";
import AnalyzeResumeError from "@/components/skill_extractor/AnalyzeResumeError.vue";
import UpskillDialog from "@/components/skill_extractor/UpskillDialog.vue";
import UploadFileCard from "@/components/skill_extractor/UploadFileCard.vue";
import SkillsCard from "@/components/skill_extractor/SkillsCard.vue";
import JobsTabs from "@/components/skill_extractor/JobsTabs.vue";
import IndustriesTabs from "@/components/skill_extractor/IndustriesTabs.vue";
import SkillsGroup from "@/components/shared/SkillsGroup.vue";
export default Vue.extend({
  data() {
    return {
      name: "Skill Extractor",
      upskill_dialog: false,
      expand_extracted_skills: false,
      expand_matched_skills: false,
      expand_required_skills: false,
      expand_skills_gap: false,
      cv_file: null,
      cv_text: "",
      file_name:
        "Upload, Drop or paste your resume/syllabi here for the best AI result",
      display_paste_cv_block: false,
      cv_id: "",
      cv_skills: [],
      top_job_sectors: [],
      active_sector_name: "",
      sector_top_jobs: [],
      top_job_id: null,
      top_job_gap: [],
      top_job_match: [],
      top_job_skills: [],
      top_job_title: "",
      top_job_matching_score: 0,
      skill_extractor_error: false,
      skill_extractor_error_msg: "",
      cv_skills_loading: false,
      top_job_sectors_loading: false,
      skills_group: skills_group,
      active_skill_group: "",
      cancel_analyze: false,
      extract_skill_group_styling: extract_skill_group_styling,
      tmp_top_job_id: -1,
      show_upskill_courses: false,
      start_job_searching: false, // To display search icon / auto complete search input
      selected_job: null, // To hold value & text of the searched job
      active_job_index: 0
    };
  },
  components: {
    SkillsGroup,
    JobsTabs,
    SkillsCard,
    UploadFileCard,
    UpskillDialog,
    AnalyzeResumeError,
    AnalyzeResumeLoader,
    PasteCvContent,
    IndustriesTabs
  },
  watch: {
    get_cv_id(newValue) {
      this.cv_id = newValue;
    },
    get_cv_skills(newValue) {
      this.cv_skills = newValue;
    },
    get_top_job_sectors(new_value, old_value) {
      if (new_value && new_value.length > 0) {
        this.top_job_sectors = new_value;
        // Case if new sector added
        if (old_value && old_value.length > 0) {
          const index = this.top_job_sectors.length;
          this.extract_sector_data(this.top_job_sectors[index - 1]);
        } else this.extract_sector_data(this.top_job_sectors[0]);
      }
    },
    get_skill_extractor_error(newValue) {
      this.skill_extractor_error = newValue;
    },
    get_skill_extractor_error_msg(newValue) {
      this.skill_extractor_error_msg = newValue;
    },
    get_cv_skills_loading(newValue) {
      this.cv_skills_loading = newValue;
    },
    get_top_job_sectors_loading(newValue) {
      this.top_job_sectors_loading = newValue;
    },
    get_cancel_analyzing(newValue) {
      if (newValue) {
        this.cancel_analyze = true;
        this.cancel_analyzing();
      } else this.cancel_analyze = false;
    }
  },
  computed: {
    ...mapGetters("skill_extractor", {
      get_cv_id: GETTER_CV_ID,
      get_cv_skills: GETTER_CV_SKILLS,
      get_top_job_sectors: GETTER_TOP_JOB_SECTORS,
      get_skill_extractor_error: GET_SKILL_EXTRACTOR_ERROR,
      get_skill_extractor_error_msg: GET_SKILL_EXTRACTOR_ERROR_MSG,
      get_cv_skills_loading: GET_CV_SKILLS_LOADING,
      get_top_job_sectors_loading: GET_TOP_JOB_SECTORS_LOADING,
      get_cancel_analyzing: GET_CANCEL_ANALYZING,
      get_search_job_loading: GET_SEARCH_JOB_LOADING,
      get_sector_index: GET_SECTOR,
      get_top_job_with_index: GET_TOP_JOB_WITH_INDEX,
      get_upload_view_resume: GET_UPLOAD_VIEW_RESUME
    })
  },
  methods: {
    ...mapActions("skill_extractor", {
      upload_cv_get_skills: UPLOAD_CV_AND_GET_SKILLS,
      fetch_top_job_sectors: GET_TOP_JOB_SECTORS,
      reset_state: RESET_STATE,
      get_upskill_courses: GET_UPSKILL_COURSES,
      cancel_cv_analyzing: CANCEL_ANALYZING
    }),
    ...mapMutations("skill_extractor", {
      set_upload_view_resume: SET_UPLOAD_VIEW_RESUME
    }),

    update_skill_group(skill_group: string) {
      if (skill_group === this.active_skill_group) this.active_skill_group = "";
      else this.active_skill_group = skill_group;
    },

    // Function to open folder uploader modal on upload button click
    open_file_uploader() {
      document.getElementById("file-uploader")?.click();
    },
    change_file(value: File) {
      this.file_name = value.name;
      this.upload_cv();
    },
    open_paste_cv_content_block() {
      this.display_paste_cv_block = true;
    },
    close_paste_content_block(value: boolean) {
      this.display_paste_cv_block = value;
    },
    // Get cv text from user
    get_cv_text(value: string) {
      this.cv_text = value;
      this.cv_file = null;
      this.file_name =
        "Upload, Drop or paste your resume/syllabi here for the best AI result";
      this.display_paste_cv_block = false;
      this.set_upload_view_resume(true);
      this.upload_cv();
    },
    // Get drag n drop file
    add_drop_file(e: any) {
      this.cv_file = e.dataTransfer.files[0];
      this.change_file(e.dataTransfer.files[0]);
    },
    // Function to upload cv (store)
    async upload_cv() {
      this.reset_local_state();
      await this.reset_state();
      let payload: { type: string; data: null | FormData | string } = {
        type: "",
        data: null
      };
      if (this.cv_file) {
        payload.type = "file";
        let formData = new FormData();
        let file: any = this.cv_file;
        formData.append("cvFile", file);
        payload.data = formData;
      } else if (this.cv_text) {
        payload.type = "text";
        payload.data = this.cv_text;
      } else {
        this.set_upload_view_resume(false);
        return;
      }
      this.set_upload_view_resume(true);
      await this.upload_cv_get_skills(payload);
      if (this.cv_id && !this.get_skill_extractor_error && !this.cancel_analyze)
        await this.fetch_top_job_sectors(this.cv_id);
    },
    /**
     * Function to extract sector data
     * @param sector Object => {name: String, top_jobs: Array}
     * @param new_added
     */
    extract_sector_data(sector: any, new_added = false) {
      this.sector_top_jobs = sector.top_jobs;
      this.active_sector_name = sector.name;
      this.active_job_index = 0;
      if (new_added) return;
      const elem = this.$refs.top_jobs_tabs as any;
      elem.reset_active_job_index();
      if (this.sector_top_jobs.length > 0) {
        this.extract_job_data(this.sector_top_jobs[0]);
      } else {
        this.active_skill_group = "";
        this.top_job_id = null;
        this.top_job_gap = [];
        this.top_job_match = [];
        this.top_job_title = "";
        this.top_job_matching_score = 0;
        this.top_job_skills = [];
        this.tmp_top_job_id = -1;
      }
    },
    /**
     * Function to extract top job data
     * @param top_job top_job Object => { job_gap: Array, job_match: Array, job_id, job_title: String, matching_score: Number, sector: String (Optional) }
     */
    extract_job_data(top_job: any) {
      this.active_skill_group = "";
      if (top_job.sector) {
        const sector_details = this.get_sector_index(top_job.sector);
        const elem = this.$refs.industry_tabs as any;
        elem.set_industry_index(sector_details.index);
        this.extract_sector_data(sector_details.sector, true);
      }
      this.top_job_id = top_job.job_id;
      this.top_job_gap = top_job.job_gap;
      this.top_job_match = top_job.job_match;
      this.top_job_title = top_job.job_title;
      this.top_job_matching_score = parseInt(
        (top_job.matching_score * 100).toFixed(0)
      );
      if (this.top_job_gap && this.top_job_match)
        this.top_job_skills = this.top_job_match.concat(this.top_job_gap);
      else this.top_job_skills = [];
    },
    remove_local_top_job(value: any) {
      const top_job_data = value;
      this.sector_top_jobs = this.sector_top_jobs.filter(
        (val: any) =>
          val.job_id !== top_job_data.job_id &&
          val.top_job_title !== top_job_data.job_title
      );
      if (this.sector_top_jobs.length > 0) {
        const top_job_new_data = this.get_top_job_with_index(
          0,
          this.active_sector_name
        );
        this.extract_job_data(top_job_new_data);
      } else {
        this.active_skill_group = "";
        this.top_job_id = null;
        this.top_job_gap = [];
        this.top_job_match = [];
        this.top_job_title = "";
        this.top_job_matching_score = 0;
        this.top_job_skills = [];
        this.tmp_top_job_id = -1;
      }
    },
    // Function to reset state (local/store)
    reset_local_state() {
      this.cv_id = "";
      this.cv_skills = [];
      this.sector_top_jobs = [];
      this.top_job_id = null;
      this.top_job_gap = [];
      this.top_job_match = [];
      this.top_job_title = "";
      this.top_job_matching_score = 0;
      this.top_job_skills = [];
      this.skill_extractor_error = false;
      this.skill_extractor_error_msg = "";
      this.cv_skills_loading = false;
      this.top_job_sectors_loading = false;
      this.show_upskill_courses = false;
      this.start_job_searching = false;
      this.selected_job = null;
    },
    async cancel_analyzing() {
      this.cv_file = null;
      this.cv_text = "";
      this.file_name =
        "Upload, Drop or past your resume/syllabi here for the best AI result";
      this.display_paste_cv_block = false;
      this.upskill_dialog = false;
      this.reset_local_state();
      await this.reset_state();
    },
    async open_upskill_dialog() {
      let job_gap: Array<string> = [];
      this.top_job_gap.forEach((val: any) => {
        job_gap.push(val.skill);
      });
      const payload = {
        response_id: this.cv_id,
        gap_list: job_gap,
        job_id: `${this.top_job_id}`
      };
      this.show_upskill_courses = true;
      await this.get_upskill_courses(payload);
    }
  },
  beforeDestroy() {
    this.reset_state();
  }
});
