
























































import Vue from "vue";
// import AutoCompleteIndustrySearch from "@/components/skill_extractor/AutoCompleteIndustrySearch.vue";
import { mapGetters, mapMutations } from "vuex";
import {
  GET_SEARCH_JOB_LOADING,
  GETTER_TOP_JOB_SECTORS,
  REMOVE_SECTOR
} from "@/store/modules/skill_extractor/constants";
export default Vue.extend({
  name: "IndustriesTabs",
  components: {
    // AutoCompleteIndustrySearch
  },
  data: (): Record<string, any> => ({
    start_sector_searching: false,
    industry_active_tab: 0
  }),
  watch: {
    sectors(new_value, old_value) {
      if (new_value && new_value.length > 0) {
        if (old_value && old_value.length > 0) {
          const index = new_value.length;
          this.industry_active_tab = index - 1;
        } else this.industry_active_tab = 0;
      }
    }
  },
  props: {
    data_loading: {
      type: Boolean,
      required: true
    },
    data: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters("skill_extractor", {
      sectors: GETTER_TOP_JOB_SECTORS,
      get_search_job_loading: GET_SEARCH_JOB_LOADING
    })
  },
  methods: {
    ...mapMutations("skill_extractor", {
      remove_sector: REMOVE_SECTOR
    }),
    set_industry_index(index: number) {
      this.industry_active_tab = index;
    }
  }
});
