























































import Vue from "vue";
import AutoCompleteJobSearch from "@/components/skill_extractor/AutoCompleteJobSearch.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  ADD_SECTOR,
  ADD_TOP_JOB,
  GET_INTERNAL_CV_ID,
  GET_SEARCHED_JOB_DATA,
  GET_TOP_JOB_INDEX,
  JOB_EXIST,
  REMOVE_TOP_JOB,
  SECTOR_EXIST
} from "@/store/modules/skill_extractor/constants";
import { SET_NOTIFICATION } from "@/store";
export default Vue.extend({
  name: "JobsTabs",
  components: { AutoCompleteJobSearch },
  data: function () {
    return {
      start_job_searching: false,
      active_job_index: 0
    };
  },
  props: {
    data_loading: {
      type: Boolean,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    sector_name: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters("skill_extractor", {
      get_cv_id: GET_INTERNAL_CV_ID,
      get_top_job_index: GET_TOP_JOB_INDEX
    })
  },
  methods: {
    ...mapActions("skill_extractor", {
      job_exist: JOB_EXIST,
      sector_exist: SECTOR_EXIST,
      get_searched_job_data: GET_SEARCHED_JOB_DATA
    }),
    ...mapMutations({
      set_global_notification: SET_NOTIFICATION
    }),
    ...mapMutations("skill_extractor", {
      remove_top_job: REMOVE_TOP_JOB,
      add_sector: ADD_SECTOR,
      add_top_job: ADD_TOP_JOB
    }),
    // Function to remove top job from store and local array in parent (skill extractor) vue
    async remove_job(value: any) {
      // Remove top job in store
      await this.remove_top_job({
        top_job: value,
        sector_name: this.sector_name
      });
      await this.$emit("remove_local_top_job", value);
      this.reset_active_job_index();
    },
    reset_active_job_index() {
      this.active_job_index = 0;
    },
    /**
     * Function to add newly searched top job data
     * @param value Object => { text: String => job title,
     * value: Object => { job_title: string, job_id: string, sector: string }
     * }
     */
    async process_selected_job(value: any) {
      this.start_job_searching = false;
      const selected_job = value;
      const job_exist_details = await this.job_exist(selected_job.text);
      if (job_exist_details) {
        this.set_global_notification("");
        // Set global notification
        this.set_global_notification(
          `Job ${selected_job.text} already exist in ${job_exist_details.name} sector`
        );
      } else {
        const sector_exist_details = await this.sector_exist(
          selected_job.value.sector
        );
        // If sector not exist: add sector
        if (!sector_exist_details) {
          await this.add_sector({
            removable: true,
            name: selected_job.value.sector,
            top_jobs: []
          });
        }
        // Fetch top job data
        const payload = {
          job: selected_job.value.job_id,
          cv: this.get_cv_id
        };
        const job_data = await this.get_searched_job_data(payload);
        if (job_data) {
          // Add top job
          const top_job: any = {
            removable: true,
            job_title: selected_job.value.job_title,
            job_id: selected_job.value.job_id,
            job_gap: job_data.gap,
            job_match: job_data.matched,
            matching_score: job_data.score
          };
          await this.add_top_job({
            sector_name: selected_job.value.sector,
            top_job: top_job
          });
          this.active_job_index = await this.get_top_job_index(
            selected_job.value.sector,
            selected_job.value.job_id,
            selected_job.value.job_title
          );
          const top_job_with_sector = {
            ...top_job,
            sector: selected_job.value.sector
          };
          await this.$emit("get_top_job_data", top_job_with_sector);
        } else {
          await this.set_global_notification("Something went wrong");
        }
      }
    }
  }
});
